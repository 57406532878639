import React from 'react'


const UseOutProfiledComp = () => {
  const stats = [
    {
      title: "76%",
      subtitle: "reduction in hiring effort",
      description: "by quickly filtering candidates with our skill scores.",
      bgColor: "bg-light-purple",
    },
    {
      title: "5x",
      subtitle: "better brand engagement",
      description: "through year-round activities to engage talent.",
      bgColor: "bg-light-blue",
    },
    {
      title: "10x",
      subtitle: "more qualified candidates",
      description: "to choose from by overcoming physical constraints.",
      bgColor: "bg-green-50", // light green background
    },
  ];
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
      {/* Heading */}
      <div className="text-center mb-12">
        <h2 className="common-header">
          Why use OutProfiled?
        </h2>
        <p className="p-tag">
          Choose jobs from the top employers and apply for the same.
        </p>
      </div>

      {/* Cards Section */}
      <div className="flex-box xl:flex gap-x-14 justify-center">
        {stats.map((stat, index) => (
          <div
            key={index}
            className={`p-6 rounded-2xl border-2 border-[#D9D9D9] mb-5 ${stat.bgColor} w-70 md:w-60`} // Adjust width here
          >
            <div className="flex">
              <h2 className="profiled-cards-header text-start">
                {stat.title} <span className="profiled-cards-span">{stat.subtitle}</span>
              </h2>
            </div>
            <p className="text-sm text-gray-600 text-start pt-4">{stat.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
export default UseOutProfiledComp;
