import React from 'react'
import Message from "../../assets/Images/Message.png"
import Call from "../../assets/Images/Calling.png"
import Addresh from "../../assets/Images/Location.png"
import mainLogo from "../../assets/Images/mainLogo.png"
const Footer = () => {
  return (
    <footer>
        <div className="mainbox relative bg-[#BEBBE950] relative px-5 xl:px-24 pt-14 pb-5">
        {/* <a href="#"><img src={Logo} alt="" className='mb-8 mx-auto w-[180px]' /></a> */}
        <a href="/" style={{ display: "flex", justifyContent: "center", alignItems: "center",paddingBottom: "30px" }}> {/* Wrap the logo in a link to the homepage */}
          <img src={mainLogo} alt="Your Logo" className="h-5 md:h-6 w-auto ml-4 md:ml-0" />
        </a>
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-y-10 items-center xl:items-start justify-between">
            <div className="logo-box ">
              
              {/* <div className="address mb-5">
                <a href="#" className='flex items-start gap-4 text-left  text-[#121224]'>
                  <img src={Addresh} alt="" />
                  <span>
                    <h4 className='font-bold'>Address:</h4>
                    Emgrad Educational<br/>
                    Mytri Square,2-41/11/6/2, Prashantinagar colony,
                    Kondapur, Serilingampally, K.V.Rangareddy-500084, Telangana
                  </span>
                </a>
              </div> */}
            </div>
            <div className="calltous xl:pl-20">
              <div className="address mb-5">
                <a href="#" className='flex items-start gap-4 text-left text-[#121224] '>
                  <img src={Call} alt="" />
                  <span>
                    <h4 className='font-bold inline'>Phone:</h4>
                      <a href="tek:+917981519961"> +91 8309067835</a>
                  </span>
                </a>
              </div>
              <div className="email">
                <p className='flex items-start gap-4 text-left text-[#121224]'>
                  <img src={Message} alt="" />
                  <span>
                    <h4 className='font-bold inline'>Email:</h4>
                    <a href="mailto:contact@gradwise.in">vardhan.p@outprofiled.com</a>
                  </span>
                </p>
              </div>
            </div>
            <div className="link text-left text-[#121224]">
              <h4 className='text-lg font-bold'>About Us</h4>
              <h4>OutProfiled is an AI-powered profile-building and job-matching platform that revolutionizes campus recruitment by streamlining drives with a pre-assessed pool of candidates. It helps students identify profile gaps, build stronger profiles, and match with suitable job opportunities through advanced AI tools, including a profile builder and recruitment portal.</h4>
            </div>
          </div>
        </div>
      </footer>
  )
}
export default Footer
